<template>
  <u-input v-on:input.native="$emit('input', $event.target.value)" :required="required" :autofocus="autofocus" ref="definput" class="erp-input erp-autocomplete float-icon input-hide-loading" color="amber" v-model="model"
           placeholder="" hide-underline v-mask="pessoa.type == 1 ? '###.###.###-##' : '##.###.###/####-##'"
           @keydown.enter.prevent="consultaCpfCnpj" @keyup="consultaCpfCnpj">
    <sl-loading v-if="loading" content="" class="size-18" />
    <u-icon v-if="regular" name="check" color="positive" title="Regular" class="sl-loading" />
    <u-icon v-if="regular === false" name="exclamation-triangle" type="fa" color="negative" title="Irregular" class="sl-loading" />
  </u-input>
</template>

<script>
import {UInput} from "uloc-vue"
import SlLoading from "@/components/layout/components/Loading"
import DocumentInput from "components/pessoa/components/include/DocumentInput"

export default {
  name: "DocumentoPessoaInput",
  mixins: [DocumentInput],
  components: {SlLoading, UInput},
  props: {
    value: {
      required: true
    },
    debounce: {
      type: Number,
      default: 500
    },
    tipo: {
      type: Number,
      default: 1
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      model: this.value
    }
  },
  watch: {
  },
  computed: {
  },
  methods: {
  }
}
</script>
